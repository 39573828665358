import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import me from "./me";
import activities from "./activities";

const reducer = combineReducers({
  me,
  activities
});

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
