import React, { useState, useEffect } from "react";

import axios from "axios";

import Lottie from "react-lottie";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";

import { loginUser } from "../../store/me";

import {
  LoginSidebar,
  LoginContent,
  Container,
  LoginLegend,
  LoginFieldset,
  LoginSubHeader,
  LoginLogo,
  LoginGrid
} from "../../styles";

import logo from "../../assets/images/login-logo.svg";

export default function LoginForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logging_in, logged_in } = useSelector((state) => state.me);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validations, setValidations] = useState({});


  if (logged_in) {
    navigate("/");
  }

  useEffect(() => {

  }, [username])

  function handleSubmit() {
    let errors = {}

    if (!username || username.length === 0) {
      errors['username'] = 'Username can not be blank'

    }

    if (!password || password.length === 0) {
      errors['password'] = 'Password can not be blank'
    }


    if (Object.keys(errors).length !== 0) {
      setValidations(errors);

      return
    }

    dispatch(loginUser({ auth: { username, password } }));
  }

  return (
    <Container>
      <LoginSidebar>
        <LoginFieldset>
          <LoginLegend>HABARI YA KWINGU!</LoginLegend>
          <LoginLogo>
            <img alt="Kwingu" src={logo} />
          </LoginLogo>
          <LoginSubHeader>
            Kwingu is your friendly <br />
            neighbourhood for all our farmer and <br />
            shamba data.{" "}
          </LoginSubHeader>
        </LoginFieldset>
      </LoginSidebar>
      <LoginContent>
        <LoginGrid
          textAlign="center"
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="black" textAlign="center">
              Sign In
            </Header>
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  fluid
                  error={validations["username"]}
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  onChange={(e, { value }) => {
                    setUsername(value);

                    if (value && value !== '') {
                      setValidations({
                        ...validations,
                        username: null
                      })
                    }

                  }}
                />
                <Form.Input
                  fluid
                  error={validations["password"]}
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={(e, { value }) => {
                    setPassword(value);

                    if (value && value !== '') {
                      setValidations({
                        ...validations,
                        password: null
                      })
                    }
                  }}
                />
                <Button
                  // color="#00391e"
                  fluid
                  size="large"
                  loading={logging_in}
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Segment>
            </Form>
            <Message>
              <a href="https://kwingu.komaza.com/reset-password">
                Request New Password
              </a>
            </Message>
          </Grid.Column>
        </LoginGrid>
      </LoginContent>
    </Container>
  );
}
