import { toast } from "react-toastify";

const defaultOption = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const toastInfo = (message, data = {}) => {
  toast.info(message, { ...defaultOption, ...data });
};

export const toastWarning = (message, data = {}) => {
  toast.warning(message, { ...defaultOption, ...data });
};

export const toastSuccess = (message, data = {}) => {
  toast.success(message, { ...defaultOption, ...data });
};

export const toastDark = (message, data = {}) => {
  toast.dark(message, { ...defaultOption, ...data });
};

export const toastError = (message, data = {}) => {
  toast.error(message, { ...defaultOption, ...data });
};

export const toastSuccessMessage = () => {
  toastSuccess("success");
};

export const toastLoginSuccessMessage = () => {
  toastSuccess("Login success");
};

export const toastAcceptSuccessMessage = () => {
  toastSuccess("accept_success");
};
