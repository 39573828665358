import React, { useState, useEffect } from 'react'
import {
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Loader,
  Dimmer,
  Form,
  Input,
  Modal,
  Button
} from 'semantic-ui-react'
import Map, { Source, Layer } from 'react-map-gl';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { fetchActivity } from "../../store/activities";

mapboxgl.accessToken = 'pk.eyJ1IjoicG9vamF2bSIsImEiOiJjanA5ZHdtaTgyOWo5M3BvMXRwNHUyc3plIn0.PetLWIk8hEi5joISFXPDhQ';

export default function Activity(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lng, setLng] = useState();
  const [lat, setLat] = useState();
  const [zoom, setZoom] = useState(18);
  const [openMap, setOpenMap] = useState(false);

  const { record } = props;
  const { core_data, form_instances } = record;
  const { farmer, shamba: { kid, geopoint } } = core_data;

  const [trees, setTrees]  = useState([]);
  const [treeForm, setTreeForm]  = useState();
  const [coordinates, setCoordinates] = useState([]);
  const [features, setFeatures] = useState([]);
  const [inventoryFeatures, setInventoryFeatures] = useState([]);
  const [inventoryPolygons, setInventoryPolygons] = useState([]);

  const treeGeoJson = {
    type: 'FeatureCollection',
    features
  };

  const treeLayerStyle = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 4,
      'circle-color': '#00391e'
    }
  };

  const inventoryGeoJson = {
    type: 'FeatureCollection',
    features: inventoryFeatures
  };

  const inventoryLayerStyle = {
    id: 'polygon',
    type: 'fill',
    paint: {
      'fill-opacity': 0.6,
      'fill-color': '#92d050'
    }
  };

  console.log(treeGeoJson)
  console.log(inventoryGeoJson)

  useEffect(() => {
    if (geopoint && geopoint.geometry && geopoint.geometry.coordinates) {
      setLng(geopoint.geometry.coordinates[0])
      setLat(geopoint.geometry.coordinates[1])
    }
  }, [geopoint])

  useState(() => {
    if (!form_instances) {
      return
    }

    const form = form_instances.find(data => data['name'] === 'tree form')
    const inventoryForm = form_instances.find(data => data['name'] === 'inventory form')

    if (form) {
      setTreeForm(form)
    }

    if (!form || !form.answers[0]) {
      return;
    }

    let answer = form.answers[0];

    if (!answer || !answer.input || !answer.input.tree_data || !answer.input.tree_data.trees) {
      return
    }

    const inputTrees = answer.input.tree_data.trees

    setTrees(inputTrees)

    setFeatures(inputTrees.map(tree => tree.geopoint))

    answer = inventoryForm.answers[0];

    if (!answer || !answer.input || !answer.input.polygon) {
      return
    }

    setInventoryFeatures([{
      'type': 'Feature',
      'geometry': {
        'type': 'Polygon',
        'coordinates': answer.input.polygon.coordinates[0]
      }
    }])
  }, [form_instances])

  return(
    <Grid stackable columns={2}>
      <Grid.Column>
        <Segment>
          <Header>Activity Details</Header>
          <Form>
            <Form.Field inline>
              <label>Activity KWID</label>
              <Input placeholder='Activity KWID' value={record.kwingu_id} />
            </Form.Field>
            <Form.Field inline>
              <label>Shamba Kcode</label>
              <Input placeholder='Shamba Kcode' value={record.shamba_code} />
            </Form.Field>
            <Form.Field inline>
              <label>Activity Template</label>
              <Input placeholder='Activity Template' value={record.activity_template} />
            </Form.Field>
            <Form.Field inline>
              <label>Due Date</label>
              <Input placeholder='Due Date' value={record.due_date} />
            </Form.Field>
            <Form.Field inline>
              <label>Submission Date</label>
              <Input placeholder='Submission Date' value={record.submission_date} />
            </Form.Field>
            <Form.Field inline>
              <label>Status</label>
              <Input placeholder='Status' value={record.status} />
            </Form.Field>
            <Form.Field inline>
              <label>Field Manager</label>
              <Input placeholder='Field Manager' value={record.fm_name} />
            </Form.Field>
            <Form.Field inline>
              <label>Field Officer</label>
              <Input placeholder='Field Officer' value={record.fo_name} />
            </Form.Field>
            <Form.Field inline>
              <label>Field Facilitator</label>
              <Input placeholder='Field Facilitator' value={record.fa_name} />
            </Form.Field>
            <Form.Field inline>
              <label>Passed</label>
              <Input placeholder='Status' value={record.passed} />
            </Form.Field>
            <Form.Field inline>
              <label>Rejection Reason</label>
              <Input placeholder='Rejection Reason' value={record.rejection_reason} />
            </Form.Field>
          </Form>
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Segment>
          <Header>Answers</Header>
          <Header>Shamba form answers</Header>
          <Form>
            <Form.Field inline>
              <label>Kid:</label>
              <Input placeholder='Activity KWID' value={kid} />
            </Form.Field>
            <Form.Field inline>
              <Button onClick={() => setOpenMap(true)}>Map</Button>
            </Form.Field>
          </Form>
        </Segment>
      </Grid.Column>

      { openMap &&

        <Modal onClose={() => setOpenMap(false)} open closeOnDimmerClick>
          <Modal.Content image>
            <Modal.Description>
              <Map
                mapLib={mapboxgl}
                initialViewState={{
                  longitude: lng,
                  latitude: lat,
                  zoom: zoom
                }}
                style={{ height: 500}}
                mapStyle="mapbox://styles/mapbox/streets-v12"
              >
                <Source id="trees" type="geojson" data={treeGeoJson}>
                  <Layer {...treeLayerStyle} />
                </Source>
                <Source id="inventory" type="geojson" data={inventoryGeoJson}>
                  <Layer {...inventoryLayerStyle} />
                </Source>
              </Map>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Close"
              labelPosition='right'
              icon='checkmark'
              onClick={() => setOpenMap(false)}
              positive
            />
          </Modal.Actions>
        </Modal>
      }
    </Grid>
  )
}
