import React, { useRef, useState, useEffect } from 'react'

import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";

import store from "./app/store";
import config from "./app/config";
import Routes from "./app/routes";

import './App.css';
import "react-toastify/dist/ReactToastify.css";

function App() {
  return(
    <Provider store={store}>
      <CookiesProvider>
        <Router>
          <Routes />
          <ToastContainer />
        </Router>
      </CookiesProvider>
    </Provider>
  )
}

export default App;
