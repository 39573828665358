import React, { useState, useEffect } from 'react'
import { Icon, Label, Menu, Table, Pagination } from 'semantic-ui-react'

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";

import { fetchActivities, setActivityFilters } from "../../store/activities";

import Loading from "../../components/loading";

export default function List() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    records,
    filters,
    fetching,
    total_count,
    initialized
  } = useSelector((state) => state.activities);

  const { page } = filters;

  useEffect(() => {
    dispatch(fetchActivities(filters))
  }, [filters])

  if (!initialized) {
    return(<Loading/>)
  }

  return(
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Kwingu ID</Table.HeaderCell>
          <Table.HeaderCell>Shamba Code</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Field Manager</Table.HeaderCell>
          <Table.HeaderCell>Version</Table.HeaderCell>
          <Table.HeaderCell>Command</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {
          records.map((record) => {
            return(
              <Table.Row>
                <Table.Cell>{record.id}</Table.Cell>
                <Table.Cell>{record.kwingu_id}</Table.Cell>
                <Table.Cell>{record.shamba_code}</Table.Cell>
                <Table.Cell>{record.status}</Table.Cell>
                <Table.Cell>{record.fm_name}</Table.Cell>
                <Table.Cell>{record.activity_versions}</Table.Cell>
                <Table.Cell>
                  <Link to={`/activity-submissions/${record.id}`}>View</Link>
                </Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan='7'>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Pagination defaultActivePage={page} totalPages={total_count} onPageChange={(event, data) => {
                dispatch(setActivityFilters({
                  ...filters, page: data.activePage
                }));
              }} />
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}
