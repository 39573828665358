import React, { useState, useEffect } from 'react'
import {  Label, Table, Pagination } from 'semantic-ui-react'
import {
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Loader,
  Dimmer,
  Button,
  Breadcrumb
} from 'semantic-ui-react'

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { getToken, logoutUser } from "../../store/me";
import { fetchActivity } from "../../store/activities";

import Loading from "../../components/loading";
import Activity from "./activity";

import logo from "../../assets/images/kwingu-logo.svg";

const sections = [
  { key: '/activity-submissions', content: 'Home', link: true },
  { key: 'activity-submissions', content: 'Activity', active: true },
]

export default function View() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    user: { name },
    logged_in,
    logging_in,
    login_in_progress,
  } = useSelector((state) => state.me);

  const {
    record,
    filters,
    fetching,
    total_count,
    initialized
  } = useSelector((state) => state.activities);


  useEffect(() => {
    dispatch(getToken())
  }, [])

  useEffect(() => {
    dispatch(fetchActivity(params['id']))
  }, [params])

  if (!logged_in) {
    navigate("/login");
  }

  return(
    <Grid columns={1} style={{height: '100%', margin: 0}}>
      <Grid.Column style={{padding: 0}}>
        <Sidebar.Pushable as={Segment} style={{border: 0, borderRadius: 0}}>
          <Sidebar
            as={Menu}
            icon='labeled'
            inverted
            vertical
            visible={true}
            width='thin'
            style={{background: '#00391e'}}
          >
            <div className="navigation" style={{
              display: 'flex',
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%"
            }}>
              <div className="main-navigation">
                <div style={{padding: 10}}>
                  <img alt="Kwingu" src={logo} style={{width: '100%'}} />
                </div>
                <Menu.Item as='a' active>
                  <Icon name='home' />
                  Activities Submissions
                </Menu.Item>
              </div>
              <div className="main-nav">
                <Menu.Item as='a' onClick={() => dispatch(logoutUser())}>
                  <Icon name='user' />
                  Log out
                </Menu.Item>
              </div>
            </div>
          </Sidebar>

          <Sidebar.Pusher style={{marginLeft: 150, transform: 'none'}}>
            <Segment basic>
              <Breadcrumb style={{marginBottom: 20}}>
                <Breadcrumb.Section link onClick={() => navigate('/activity-submissions')}>Home</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>Activity</Breadcrumb.Section>
              </Breadcrumb>

              <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'flex-end' }}>
                <Button.Group>
                  <Button color='red'>Reject</Button>
                  <Button.Or />
                  <Button color='teal'>Resubmit</Button>
                  <Button.Or />
                  <Button color='olive'>Approve</Button>
                </Button.Group>
              </div>

              <Dimmer active={fetching && initialized} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>

              {
                record ? <Activity record={record} /> : <Loading/>
              }
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Grid.Column>
    </Grid>
  )
}
