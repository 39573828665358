import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Loading from "../../components/loading";
import {Container} from '../../styles'

import { getToken } from "../../store/me";

export default function Main() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user: { name },
    logged_in,
    logging_in,
    login_in_progress,
  } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getToken())
  }, [])

  if (login_in_progress) {
    return(<Container style={{height: '100vh'}}><Loading /></Container>)
  }

  if (!logged_in) {
    navigate("/login");
  } else {
    navigate("/activity-submissions")
  }
}
