import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, create, update, destroy } from "../api";
import {
  toastInfo,
  toastSuccess,
  handleMeResponse,
  handleActionResponse,
  toastLoginSuccessMessage,
  toastUpdateSuccessMessage,
  toastAcceptSuccessMessage,
} from "../utils";

const initialState = {
  fetch: true,
  initialized: false,
  fetching: false,
  record: null,
  records: [],
  filters: {
    page: 1,
    per_page: 10
  },
  validations: {},
};

const slice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    fetching: (state) => {
      state.fetching = true;
    },
    fetched: (state) => {
      state.fetched = true;
      state.fetching = false;
    },
    setRecord: (state, action) => {
      state.record = action.payload;
    },
    setRecords: (state, action) => {
      const { records, total_count } = action.payload;
      state.initialized = true;
      state.records = records;
      state.total_count = total_count;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetInitial: () => {
      return { ...initialState, login_needed: true };
    },
  },
});

export default slice.reducer;

const {
  fetching,
  fetched,
  setRecord,
  setRecords,
  setFilters
} = slice.actions;

export const fetchActivities = (filters) => async (dispatch) => {
  try {
    dispatch(fetching());

    const response = await get({
      endpoint: "/api/v1/activities/search",
      data: filters,
    });

    const { data } = response;

    const {
      activities, total, total_records, all_activities_count
    } = data;


    dispatch(setRecords({
      records: activities,
      total_count:  total
    }))

    dispatch(fetched())
  } catch (e) {
    console.log(e)
  }
};

export const fetchActivity = (id) => async (dispatch) => {
  try {
    dispatch(fetching());

    const response = await get({
      endpoint: `/api/v1/activities/${id}`
    });

    const { data } = response;

    const {
      activities, total, total_records, all_activities_count
    } = data;


    // activity_template
    // :
    // "Tree Count Blanking C1"
    // activity_versions
    // :
    // 1
    // code
    // :
    // "S-TC-C1-B-1"
    // core_data
    // :
    // {,…}
    // cycle
    // :
    // 1
    // drop_out_reason
    // :
    // null
    // fa_id
    // :
    // 3409
    // fa_name
    // :
    // "Mwaura Mohamed Mwanganzi"
    // fm_id
    // :
    // 51047
    // fm_name
    // :
    // "Andrew Odung'uli Kalitsas"
    // fo_id
    // :
    // 7719
    // fo_name
    // :
    // "Anna Muya Maitha"
    // form_instances
    // :
    // [{id: 1484990, name: "shamba form", answers: [{id: 1064538,…}]},…]
    // id
    // :
    // 620882
    // kwingu_id
    // :
    // "cb2b7c20"
    // rejection_reason
    // :
    // null
    // shamba_code
    // :
    // "kwl-2023-0142"
    // shamba_id
    // :
    // 50980
    // start_date
    // :
    // "2023-06-05"
    // status
    // :
    // "approved"
    // submission_date
    // :
    // "2023-06-07"
    // submitted_by
    // :
    // "Mwaura Mohamed Mwanganzi"
    //
    dispatch(setRecord(response.data))

    dispatch(fetched())
  } catch (e) {
    console.log(e)
  }
};

export const setActivityFilters = (filters) => async (dispatch) => {
  dispatch(setFilters(filters));
};
