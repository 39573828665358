import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { getCookie, setCookie, removeCookie } from "../cookie";
import { setAccessToken } from "../utils";
import { get, create, update, destroy } from "../api";
import {
  toastInfo,
  toastSuccess,
  handleMeResponse,
  handleActionResponse,
  toastLoginSuccessMessage,
  toastUpdateSuccessMessage,
  toastAcceptSuccessMessage,
} from "../utils";

const initialState = {
  fetch: true,
  user: {
    locale: "en",
    timezone: "UTC",
  },
  token: null,
  logging_in: false,
  fetched: false,
  fetching: true,
  login_in_progress: true,
  loading: true,
  logged_in: false,
  login_needed: false,
  validations: {},
};

const slice = createSlice({
  name: "me",
  initialState,
  reducers: {
    fetching: (state) => {
      state.fetching = true;
    },
    fetched: (state) => {
      state.fetched = true;
      state.fetching = false;
    },
    loginStarted: (state) => {
      state.logging_in = true;
    },
    logout: (state) => {
      state.token = null;
      state.logged_in = false;
      state.logging_in = false
    },
    loginSuccess: (state, action) => {
      const {user, person} = action.payload;

      state.fetch = false;
      state.logging_in = false;
      state.fetching = false;
      state.logged_in = true;
      state.validations = {};
      state.user = user;
      state.person = person;
    },
    loginFailed: (state) => {
      state.logging_in = false;
      state.login_in_progress = false;
    },
    loginNotNeeded: (state) => {
      state.login_needed = false;
      state.logged_in = true;
      state.login_in_progress = false;
    },
    gettingMe: (state) => {
      state.logging_in = true;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    resetInitial: () => {
      return { ...initialState, login_needed: true };
    },
  },
});

export default slice.reducer;

const {
  fetching,
  fetched,
  logout,
  gettingMe,
  loginStarted,
  loginSuccess,
  loginFailed,
  loginNotNeeded,
  setToken,
  resetInitial,
  handleValidations,
} = slice.actions;

export const getToken = () => async (dispatch) => {
  try {
    dispatch(gettingMe());

    const token = localStorage.getItem('token');

    if (token) {
      dispatch(setToken(token))
      dispatch(loginNotNeeded())
      setAccessToken(token)
    } else {
      dispatch(loginFailed());
    }
  } catch (e) {
    console.log(e)
    dispatch(loginFailed());
  }
};

export const loginUser = (data) => async (dispatch, getState) => {
  try {
    dispatch(loginStarted());

    const response = await create({
      endpoint: "/api/v1/sessions/sign_in",
      data,
    });

    localStorage.setItem("token", response.headers['x-access-token'])

    dispatch(setToken(response.headers['x-access-token']))

    const { user, person } = response.data;

    toastLoginSuccessMessage();
    dispatch(loginSuccess({user, person}));
  } catch (response) {
    dispatch(loginFailed());

    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem('token');

    dispatch(logout())
    toastSuccess('Logged out');
  } catch (e) {
    console.log(e)
  }
};
