import React from "react";

import Lottie from "react-lottie";

import * as notfound from "../../assets/lotties/not-found.json";

export default function NotFound() {
  return (
    <Lottie
      width={400}
      height={400}
      options={{
        loop: true,
        autoplay: true,
        animationData: notfound.default,
      }}
      isStopped={false}
      isPaused={false}
    />
  );
}
