import styled from "styled-components";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";

import backgroundImage from "./assets/images/login-background.svg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg,#f7f7f7,#f7f7f7);

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: inherit;
    justify-content: inherit;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  min-height: 410px;
  flex-direction: column;
  justify-content: space-between;
`;

export const LoginSidebar=styled.div`
  flex: 1.5;
  height: 100vh;
  background: #00391e;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    padding-top: 20px;
  }
`
export const LoginGrid=styled(Grid)`
  height: 100vh;

  @media only screen and (max-width: 800px) {
    height: auto;
  }
`
export const LoginContent=styled.div`
  background: linear-gradient(0deg,#f7f7f7,#f7f7f7);
  flex: 2.5;
  height: 100vh;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    flex: 1.5;
    flex-direction: column;
    align-items: inherit;
    justify-content: inherit;
    height: auto;
    padding-top: 40px;
  }
`
export const LoginFieldset = styled.fieldset`
  border-width: 1px 0 0;
  border-style: solid;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-color: rgba(255,255,255,.5);
`
export const LoginSubHeader = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  text-align: center;
  color: rgba(255,255,255,.7);
`

export const LoginLegend=styled.legend`
  padding: 0 10px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #fff;
`
export const LoginLogo=styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

export const GridAction = styled.div``;
export const GridPagination = styled.div``;

export const TabPaneStyles = { border: "none", boxShadow: "none" };
