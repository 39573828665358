import React from "react";

import { Route, Routes } from "react-router-dom";

import Main from "./screens/main";
import Login from "./screens/login";
import NotFound from "./screens/not_found";
import ActivitySubmissions from "./screens/activity-submissions";
import ActivitySubmissionView from "./screens/activity-submissions/view";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/activity-submissions" element={<ActivitySubmissions />} />
      <Route exact path="/activity-submissions/:id" element={<ActivitySubmissionView />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
