import React, { useState, useEffect } from "react";

import Lottie from "react-lottie";
import {
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Loader,
  Dimmer
} from 'semantic-ui-react'

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import 'mapbox-gl/dist/mapbox-gl.css';

import Loading from "../../components/loading";
import {Container} from '../../styles'
import List from './list'

import logo from "../../assets/images/kwingu-logo.svg";

import { getToken, logoutUser } from "../../store/me";

export default function Main() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fetchingUser, setFetchingUser] = useState(false);

  const {
    user: { name },
    logged_in,
    logging_in,
    login_in_progress,
  } = useSelector((state) => state.me);
  const {
    fetching,
    initialized
  } = useSelector((state) => state.activities);

  useEffect(() => {
    dispatch(getToken())
  }, [])

  if (!logged_in) {
    navigate("/login");
  }

  if (login_in_progress) {
    return(<Container style={{height: '100vh'}}><Loading /></Container>)
  }

  return(
    <Grid columns={1} style={{height: '100%', margin: 0}}>
      <Grid.Column style={{padding: 0}}>
        <Sidebar.Pushable as={Segment} style={{border: 0, borderRadius: 0}}>
          <Sidebar
            as={Menu}
            icon='labeled'
            inverted
            vertical
            visible={true}
            width='thin'
            style={{background: '#00391e'}}
          >
            <div className="navigation" style={{
              display: 'flex',
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%"
            }}>
              <div className="main-navigation">
                <div style={{padding: 10}}>
                  <img alt="Kwingu" src={logo} style={{width: '100%'}} />
                </div>
                <Menu.Item as='a' active>
                  <Icon name='home' />
                  Activities Submissions
                </Menu.Item>
              </div>
              <div className="main-nav">
                <Menu.Item as='a' onClick={() => dispatch(logoutUser())}>
                  <Icon name='user' />
                  Log out
                </Menu.Item>
              </div>
            </div>
          </Sidebar>

          <Sidebar.Pusher style={{marginLeft: 150, transform: 'none'}}>
            <Segment basic>
              <Header.Content as='h3'>Activities Submissions</Header.Content>
              <Dimmer active={fetching && initialized} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>

              <List/>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Grid.Column>
    </Grid>
  )
}
